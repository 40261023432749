.mainPhoneNumber {
  border-radius: 5px;
  width: 100%;
  cursor: text;
  font-weight: bold;
  background-color: white;
  border: none;
  border: 1px solid #fafafa;
  height: 53px;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: black;
}
.PhoneTimer * {
  font-size: 18px;
  color: #1c2123;
  background-color: white !important;
  border: none !important;
  outline: none !important;
  left: 7px;
  font-weight: bold;
}
.mainPhoneNumber * {
  font-size: 18px;
  color: #1c2123;
  left: 7px;
  background-color: white !important;
  border: none !important;
  outline: none !important;
  font-weight: bold;
  left: 7px;
}

.PhoneTimer {
  border-radius: 5px;
  width: 137%;
  margin-left: -40px;
  cursor: text;
  font-weight: bold;
  background-color: white;
  border: none;
  border: 1px solid #bdbdbd;
  height: 53px;
  color: #1c2123;
}
@media (max-width: 275px) {
  .PhoneTimer {
    width: 80% !important;
    margin-left: 10% !important;
  }
}