html,
body {
  margin: 0;
  padding: 0;
}
* {
  transition: all 0.2s ease-in-out;
}
@font-face {
  font-family: "bob";
  src: url("/public/bob.ttf");
}
